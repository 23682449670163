import { classNames } from "@core/lib/classNames";
import styles from "./SkipToContent.module.css";

export const SkipToContent = () => {
  return (
    <button
      class={classNames(styles["skip-to-content"], "screen-reader")}
      type="button"
      onFocusIn={(event) => {
        const target = event.target as HTMLAnchorElement;
        target.classList.remove("screen-reader");
      }}
      onFocusOut={(event) => {
        const target = event.target as HTMLAnchorElement;
        target.classList.add("screen-reader");
      }}
      onClick={(event) => {
        const focusTarget = document.getElementById("main-content") as HTMLAnchorElement;
        if (focusTarget) {
          focusTarget.focus();
          event.preventDefault();
        }
      }}
      tabIndex={0}
    >
      Skip to main content
    </button>
  );
};
